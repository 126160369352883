<template>
  <div class="faq relative landing-page-wrapper new-langing-ui">
    <landing-page-header
    
    />
    <!-- faq search section -->
    <section
      id="faq-search-filter"
      class="content-info no-white-space"
    >
      <b-card
        no-body
        class="faq-search"
        :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t("Let's answer some questions") }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t('or choose a category to quickly find the help you need') }}
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqFormData"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <b-img
              href="javascript:void(0)"
              width="24"
              class="mr-1"
              :src="$helpers.imageHelper(categoryObj.icon)"
              @error="$helpers.imageError"
            />
            <span class="font-weight-bold">{{ categoryObj.category }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>{{ $t('You still have a question?') }}</h2>
          <b-card-text class="mb-3">
            {{ $t('If you cannot find a question in our FAQ, you can always contact us') }}. {{ $t('We will answer to you shortly!') }}
          </b-card-text>
        </b-col>
        <b-col sm="12">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>business@momitnow.com</h4>
            <span class="text-body">{{ $t('Best way to get answer faster!') }}</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <landing-page-footer />
    <AppFooterBlack />
  </div>
</template>

<script>
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import FaqQuestionAnswer from './faq/FaqQuestionAnswer.vue'
import { useUserUi } from './users/useUser'

export default {
  components: {
    FaqQuestionAnswer,
    LandingPageHeader,
    LandingPageFooter,
    AppFooterBlack,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqFormData: {},
      originalData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  mounted() {
    this.getFAQList()
  },
  methods: {
    getFAQList() {
      const { getFAQListAll } = useUserUi()
      showLoader()
      getFAQListAll()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            let faqTempData = data.responseData
            faqTempData = faqTempData.filter(x => x.category && x.category_id)
            faqTempData = window.SuperApp.actions.groupArray(faqTempData, x => x.category_id)
            const tempData = {}
            faqTempData.forEach(item => {
              tempData[item[0].category.name] = {
                qandA: item
                  .filter(x => Array.isArray(x.questions))
                  .flatMap(x => x.questions)
                  .map(x => ({
                    ans: x.answer,
                    question: x.question,
                  })),
                icon: item[0].category.picture,
                category: item[0].category.name,
                title: item[0].title,
                subtitle: item[0].subtitle,
              }
            })
            this.faqFormData = tempData
            this.originalData = window.SuperApp.actions.cloneDeep(this.faqFormData)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    fetchData() {
      const clonedData = window.SuperApp.actions.cloneDeep(this.originalData)
      let queryLowered = this.faqSearchQuery.toLowerCase()
      if (!queryLowered) {
        this.faqFormData = clonedData
        return
      }
      queryLowered = queryLowered.trim()
      if (!queryLowered) {
        this.faqFormData = clonedData
        return
      }
      const filteredData = {}

      Object.entries(clonedData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
          return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
          filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
      })
      this.faqFormData = filteredData
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 6rem;
}

#faq-tabs {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 767px) {
  #faq-tabs {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>
