<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-img
        href="javascript:void(0)"
        width="24"
        class="mr-1"
        :src="$helpers.imageHelper(options.icon)"
        @error="$helpers.imageError"
      />
      <div>
        <h4 class="mb-0">
          {{ options.title }}
        </h4>
        <span>{{ options.subtitle }}</span>
      </div>
    </div>

    <!-- collapse -->
    <app-collapse
      id="faq-payment-qna"
      accordion
      type="margin"
      class="mt-2"
    >
      <app-collapse-item
        v-for="(data, index) in options.qandA"
        :key="index"
        :title="data.question"
      >
        {{ data.ans }}
      </app-collapse-item>
    </app-collapse>

    <!--/ collapse -->
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
